<template>
  <div v-if="!allEmpty" class="partner-tenant-all-features-tab-container">
    <div class="tabs-container">
      <div
        v-for="(tab, index) in tabsList"
        :key="index"
        class="tab"
        :class="isTabActive(index) ? 'active' : ''"
        tabindex="0"
        :aria-label="`Show ${tab.title} tab`"
        @click="changeDisplayedTab(index)"
        @keyup.enter="changeDisplayedTab(index)"
      >
        <div class="tab-icon" v-html="tab.icon"></div>
        <div class="tab-title">
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div class="content-container">
      <button
        class="left-arrow arrow-button"
        aria-label="Show previous page"
        @click="showPreviousPage"
        @keyup.enter="showPreviousPage"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        class="right-arrow arrow-button"
        aria-label="Show next page"
        @click="showNextPage"
        @keyup.enter="showNextPage"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <div class="empty-message-container" v-if="activeListEmpty">
        <p class="message">No {{ getActiveType }} have been posted yet.</p>
        <p class="sub-message">Check back later!</p>
      </div>
      <div class="content-cell" v-for="(item, index) in paginate(getActiveItemsList(), 4, activePage)" :key="index">
        <resource-card :item="item" v-if="getActiveType === 'resources'" class="resource-card"></resource-card>
        <partner-event-card v-else :item="item" @toggle-bookmark="toggleBookmark"></partner-event-card>
      </div>
    </div>
    <div class="slider-container">
      <slider-dots
        ref="recommendedOpportunitiesSlider"
        :totalNo="activeTabListCount"
        :initialSelectedNo="1"
        @changeSelected="changeDisplayedPageHandler"
      />
    </div>
    <div class="see-all-button-container">
      <a class="dabi-main-button-outlined see-all-button" aria-label="See all opportunities" :href="seeAllUrl">
        See all
      </a>
    </div>
  </div>
</template>
<script>
import BookmarkService from '../../bookmarks/services/BookmarkService';
import * as Cookies from 'js-cookie';
import SliderDots from '../../partners/components/landing_pages/components/SliderDots.vue';
import EventsIndexService from '../../events/services/EventsIndexService';
import ResourceLibraryService from '../../library/services/ResourceLibraryService';
import ResourceCard from '../../library/components/ResourceCard.vue';
import PartnerEventCard from '../../events/components/cards/PartnerEventCard.vue';

export default {
  name: 'content-card',
  components: {
    SliderDots,
    EventsIndexService,
    ResourceCard,
    PartnerEventCard,
  },
  props: ['partner_name', 'hasEventsAccess', 'hasProgramsAccess'],
  computed: {
    activeTabListCount() {
      return this.getPagesList(this.tabsList[this.activeTabId].items).length;
    },
    tabsCount() {
      return this.tabsList.length;
    },
    tabsIdsArray() {
      return [...Array(this.tabsCount).keys()];
    },
    getActiveType() {
      return this.tabsList[this.activeTabId].type;
    },
    activeListEmpty() {
      return this.activeTabListCount === 0;
    },
    seeAllUrl() {
      if (this.partner_name) {
        return `/partners/${this.partner_name}?tab=${this.getActiveType}`;
      } else if (this.getActiveType === 'resources') {
        return `/library`;
      }
      return '/opportunities';
    },
  },
  data() {
    return {
      eventService: new EventsIndexService(),
      eventBookmarkService: new BookmarkService(),
      resourcesServices: new ResourceLibraryService(),
      tabsList: [
        {
          icon: '<i class="fa-light fa-earth-africa"></i>',
          title: 'Opportunities',
          type: 'opportunities',
          items: [],
        },
        {
          icon: '<i class="fa-light fa-calendar-star"></i>',
          title: 'Events',
          type: 'events',
          items: [],
        },
        {
          icon: '<i class="fa-light fa-diploma"></i>',
          title: 'Resources',
          type: 'resources',
          items: [],
        },
      ],
      opportunities_list: [],
      activePage: 1,
      allEmpty: false,
      activeTabId: 0,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    this.allEmpty = true;
    this.handleNavItems();
    for (var item of this.tabsList) {
      if (item.type === 'opportunities') {
        item.items = await this.getPrograms(item.type);
        item.items = item.items.opportunities;
      } else if (item.type === 'events') {
        item.items = await this.getEvents();
        item.items = item.items.opportunities;
      } else if (item.type === 'resources') {
        item.items = await this.getResources();
      }
      console.log('item.items.length', item.items.length);

      if (item.items && item.items.length > 0) {
        this.allEmpty = false;
      }
    }
    console.log('this.tabsList 1', this.tabsList);
    if (!this.allEmpty) this.tabsList = this.tabsList.filter((tab) => tab.items.length > 0);
    console.log('this.tabsList 2', this.tabsList);
  },
  methods: {
    handleNavItems() {
      if (!this.hasEventsAccess) {
        this.tabsList = this.tabsList.filter((e) => e.type !== 'events');
      }
      if (!this.hasProgramsAccess) {
        this.tabsList = this.tabsList.filter((e) => e.type !== 'opportunities');
      }
    },
    formatTime(date, time) {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const [year, month, day] = date.split('-');
      const [hour, minute, period] = time ? time.split(':') : ['', '', ''];

      let formattedHour = '';
      let formattedMinute = '';
      let formattedTime = '';
      if (hour && minute && period) {
        formattedHour = hour === '00' ? '12' : hour > 12 ? hour - 12 : hour;
        formattedMinute = minute.padStart(2, '0');
        formattedTime = `, ${formattedHour}:${formattedMinute} ${period.toUpperCase()}`;
      }

      const dayName = days[new Date(year, month - 1, day).getDay()];
      const monthName = months[month - 1];

      return `${dayName}, ${monthName} ${parseInt(day)}${formattedTime}`;
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    getPagesList(array, page_size = 4) {
      let result = Math.floor(array.length / page_size);
      if (array.length % page_size > 0) {
        result = result + 1;
      }
      return Array(result)
        .fill()
        .map((element, index) => index + 1);
    },
    getActiveItemsList() {
      return this.tabsList[this.activeTabId].items ? this.tabsList[this.activeTabId].items : [];
    },
    async getResources() {
      const featuredResourcesList = await this.resourcesServices.getFeatured({
        partner_name: this.partner_name,
        limit: 12,
      });
      const resourcesList = this.partner_name
        ? await this.resourcesServices.getResourcesDabi({ partners: [this.partner_name], limit: 6 })
        : await this.resourcesServices.getResources({ limit: 6 });
      const normalizedResources = resourcesList.resources ? resourcesList.resources : [];
      return [...featuredResourcesList, ...normalizedResources];
    },
    async getEvents() {
      const params = {
        type: 'event',
        partners_name: this.partner_name,
      };
      if (this.partner_name) return await this.eventService.getEventsDabi({ ...params, q: '', page: 1, limit: 12 });
      else return await this.eventService.getEvents({ ...params, q: '', page: 1, limit: 12 });
    },
    async getPrograms(type) {
      const params = {
        type: 'program',
        partners_name: this.partner_name,
      };
      if (this.partner_name) return await this.eventService.getEventsDabi({ ...params, q: '', page: 1, limit: 12 });
      else return await this.eventService.getEvents({ ...params, q: '', page: 1, limit: 12 });
    },
    isTabActive(index) {
      return this.activeTabId == index;
    },
    changeDisplayedTab(index) {
      this.activeTabId = index;
      this.$refs.recommendedOpportunitiesSlider.initSelection();
    },
    isPageActive(page_number) {
      return this.activePage == page_number;
    },
    changeDisplayedPageHandler(index) {
      this.changeDisplayedPage(index);
    },
    changeDisplayedPage(page_number) {
      this.activePage = page_number;
    },
    showPreviousPage() {
      this.$refs.recommendedOpportunitiesSlider.selectPrevious();
    },
    showNextPage() {
      this.$refs.recommendedOpportunitiesSlider.selectNext();
    },
    isOnline(locationString) {
      return locationString === 'Online';
    },
    toggleBookmark(item) {
      if (item.is_bookmarked) {
        const payload = {
          targets: [item.id],
        };
        this.eventBookmarkService.deleteBookmarkedEvents(payload, this.headers);
        item.is_bookmarked = false;
      } else {
        const payload = {
          event_id: item.id,
        };
        this.eventBookmarkService.postBookmarkEvent(payload, this.headers);
        item.is_bookmarked = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.see-all-button-container {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.empty-message-container {
  display: flex;
  width: 100%;
  height: 270px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  grid-column: 2 / 4;
  text-align: center;

  .message {
    color: #444444;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .sub-message {
    color: #494949;
    font-size: 1rem;
    line-height: 1;
  }
}

.resource-card {
  width: unset;
}

.partner-tenant-all-features-tab-container .content-cell {
  z-index: unset;
}

.content-cell {
  height: 283px;
}

.start-date-time {
  color: #838ea1;
  font-size: 0.875rem;
  line-height: 1;
}

@media screen and (max-width: 576px) {
  .partner-tenant-all-features-tab-container .content-container {
    height: 284px;
  }
}
</style>
