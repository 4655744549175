import { EventBus } from '../eventBus';

export default {
  data() {
    return {
      fullWSections: [],
    };
  },
  created() {
    EventBus.$on('sidebarToggle', this.handleSidebarToggle);
  },
  beforeDestroy() {
    EventBus.$off('sidebarToggle', this.handleSidebarToggle);
  },
  mounted() {
    this.getFullWSections();
    this.setFullWSectionsBasedOnSidebar(true);
  },
  methods: {
    getFullWSections() {
      if (this.$el.classList.contains('full-w-section-based-on-sidebar')) {
        this.fullWSections.push(this.$el);
      }

      const fullWSections = this.$el.querySelectorAll('.full-w-section-based-on-sidebar');

      for (let fullWSectionEl of fullWSections) {
        let isChildComponentEl = false;

        for (let childComponent of this.$children) {
          if (childComponent.$el.contains(fullWSectionEl)) {
            isChildComponentEl = true;
            break;
          }
        }

        if (!isChildComponentEl) {
          this.fullWSections.push(fullWSectionEl);
        }
      }
    },
    setFullWSectionsBasedOnSidebar(initial_load = false) {
      this.fullWSections.forEach((sectionEl) => {
        EventBus.$emit('setFullWSectionBasedOnSidebar', sectionEl, initial_load);
      });
    },
    handleSidebarToggle() {
      this.setFullWSectionsBasedOnSidebar();
    },
  },
};
