import { defineStore } from 'pinia';
import CommonDataService from '../services/CommonDataService';

export const useCommonDataStore = defineStore({
  id: 'common-data',
  state: () => ({
    departments: [],
    focus_areas: [],
    audiences: [],
    identities: [],
    cae: [],
    featureNames: {}
  }),
  getters: {
    getCommonData: (state) => {
      return {
        departments: state.departments,
        focus_areas: state.focus_areas,
        audiences: state.audiences,
        identities: state.identities,
        cae: state.cae
      }
    },
    getSingleDataArray: (state) => {
      return (item_name) => state[item_name]
    },
    getFeatureName: (state) => {
      return (item_name) => state.featureNames[item_name]
    },
  },
  actions: {
    restart() {
      this.departments = [];
      this.focus_areas = [];
      this.audiences = [];
      this.identities = [];
      this.cae = [];
    },
    async loadFeatureNames() {
      const service = new CommonDataService();
      const res = await service.getFeatureNames();
      this.featureNames.department_plural = res.feature_names.department_plural;
      this.featureNames.department_singular = res.feature_names.department_singular;
    },
    loadData() {
      const service = new CommonDataService();

      service.getDepartmentsAndFocusAreas().then(data => {
        this.departments = data.departments;
        this.focus_areas = data.focus_areas;
        this.audiences = data.audiences;
        this.identities = data.identities;
        this.cae = data.career_academic_interests
      })
    }
  },
})
