<template>
  <div v-if="isPageLoading">
    <loading-spinner wrapperClass="wrapper" />
  </div>
  <div v-else class="flex-column-center">
    <div class="main-wrapper">
      <div class="title-wrapper">
        <common-title
          title="Opportunities"
          :description="`Browse ${
            hasProgramsAccess && hasEventsAccess ? 'events and programs' : hasEventsAccess ? 'events' : 'opportunities'
          } posted by your university.`"
        />
        <div v-if="canManage || isManager">
          <div v-if="hasProgramsAccess && hasEventsAccess" class="desktop-only">
            <popup-component buttonText="Create">
              <template #items>
                <a class="eu-resource-burger-el" aria-label="Go to events creation page" :href="createEventLink">
                  <i class="fa-solid fa-calendar-pen" style="padding-right: 5px"></i>
                  <span>Event</span>
                </a>
                <a class="eu-resource-burger-el" aria-label="Go to program creation page" :href="createProgramLink">
                  <i class="fa-solid fa-building-columns" style="padding-right: 5px"></i>
                  <span>Program</span>
                </a>
              </template>
            </popup-component>
          </div>
          <div v-else-if="hasProgramsAccess">
            <a
              class="iu-main-btn main solid x-small add-opportunities-button-alignment"
              style="width: 100%"
              aria-label="Go to program creation page"
              :href="createProgramLink"
            >
              <i class="fa-solid fa-landmark iu-main-button-front-icon"></i>
              Add Program
            </a>
          </div>
          <div v-else-if="hasEventsAccess">
            <a
              aria-label="Go to events creation page"
              :href="createEventLink"
              class="iu-main-btn main solid x-small add-opportunities-button-alignment"
              style="width: 100%"
            >
              <i class="fa-solid fa-calendar-pen iu-main-button-front-icon"></i>
              Add Event
            </a>
          </div>
          <div class="mobile-only add-opportunities-buttons-container">
            <span v-if="hasProgramsAccess && hasEventsAccess" class="mobile-options">
              <a
                v-if="hasEventsAccess"
                aria-label="Go to events creation page"
                :href="createEventLink"
                class="iu-main-btn main solid x-small add-opportunities-button-alignment"
                style="width: 100%"
              >
                <i class="fa-solid fa-calendar-pen iu-main-button-front-icon"></i>
                Add Event
              </a>
              <a
                v-if="hasProgramsAccess"
                class="iu-main-btn main solid x-small add-opportunities-button-alignment"
                style="width: 100%"
                aria-label="Go to program creation page"
                :href="createProgramLink"
              >
                <i class="fa-solid fa-landmark iu-main-button-front-icon"></i>
                Add Program
              </a>
            </span>
          </div>
        </div>
      </div>

      <navigation-tabs
        v-if="isPartnerTenant"
        :navigationItems="navigationItems"
        :preventRedirect="true"
        @changeTab="changePage"
        :initialTab="currentPage"
        class="partner-nav-container"
      ></navigation-tabs>
      <nav v-else class="tab-navigation-container">
        <span
          tabindex="0"
          class="tab-navigation-item"
          :class="currentPage === 'browse' ? 'active' : ''"
          @click="changePage('browse')"
          @keyup.enter="changePage('browse')"
          role="link"
        >
          Browse <span class="desktop-only-text">Opportunities</span>
        </span>
        <router-link
          v-if="canManage"
          to="/opportunities/manage"
          class="tab-navigation-item"
          aria-label="Go to manage event page"
        >
          Manage <span class="desktop-only-text">Opportunities</span></router-link
        >
        <span
          tabindex="0"
          class="tab-navigation-item"
          :class="currentPage === 'saved' ? 'active' : ''"
          @click="changePage('saved')"
          @keyup.enter="changePage('saved')"
          role="link"
        >
          Saved<span class="desktop-only-text">/Upcoming</span>
        </span>
      </nav>
      <div
        class="event-search-with-background-image-container mobile-full-w"
        :style="`background-image: url(${searchBackgroundImage}); background-size: cover; background-position: center;`"
      >
        <div class="tint"></div>
        <div style="display: flex; align-items: center; justify-content: center; flex-direction: column">
          <h1 class="event-search-with-background-image-title">{{ searchSectionTitle }}</h1>
          <p class="event-search-with-background-image-description">{{ searchSectionDescription }}</p>
        </div>
        <search-base
          ref="searchOpportunities"
          id="search-opportunities"
          placeholder="Search opportunities"
          aria-label="Search opportunities"
          style="width: 100%; margin-top: 1.5rem"
          @search="search"
          v-if="isPartnerTenant"
        ></search-base>
        <form
          v-else
          action=""
          @submit.prevent="fetchEvents()"
          class="eu-search-form"
          style="margin-top: 24px; position: relative"
          role="search"
        >
          <input
            v-model="search_input"
            name="q"
            type="search"
            placeholder="Search for opportunities"
            class="eu-search-input"
          />
          <button
            class="eu-search-btn-icon"
            style="
              padding: 14px 19px 10px 15px;
              right: 0;
              background-color: #5e50b5;
              border-radius: 0px 8px 8px 0;
              height: 50px;
            "
            aria-label="search"
          >
            <i class="eu-search-icon fas fa-search" style="color: #fff"></i>
          </button>
        </form>
      </div>
      <div class="filter-container content-filters-container" v-if="!isMobile">
        <multiselect-filter-component
          v-if="hasEventsAccess && hasProgramsAccess"
          placeholder="Any Type"
          filter_name="Events &amp; Program"
          style="margin: 0 !important"
          :options="type_of_entity"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="isPartnerTenant"
          placeholder="Any Career/Academic"
          filter_name="Career/Academic interests"
          style="margin: 0 !important"
          :options="filterData.career_academic_interests"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="isPartnerTenant"
          placeholder="Any Location"
          filter_name="Destination"
          style="margin: 0 !important"
          :options="filterData.countries"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          placeholder="Any Focus Area"
          filter_name="Focus Areas"
          style="margin: 0 !important"
          :options="filterData.focusAreas"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="isPartnerTenant"
          placeholder="Any Partner"
          filter_name="Partners"
          style="margin: 0 !important"
          :options="filterData.partners"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="areDeptsEnabled"
          :placeholder="`Any ${deptSingular}`"
          filter_name="Departments"
          style="margin: 0 !important"
          :options="filterData.departments"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!isPartnerTenant"
          placeholder="Any Identity"
          filter_name="Identities"
          style="margin: 0 !important"
          :options="filterData.identities"
          @filter-data="createFilterData"
        />

        <multiselect-filter-component
          v-if="!isPartnerTenant"
          placeholder="Upcoming"
          filter_name="Upcoming &amp; past"
          style="margin: 0 !important"
          :options="status_options"
          @filter-data="createFilterData"
        />
      </div>
      <div class="option-pills-container" v-if="isMobile">
        <div v-if="showSearchOverallSection && isMobile" class="search-overall-container" style="margin: 0">
          <button class="search-overall-back-button" @click="closeSearchResults" aria-label="Close search results">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <span class="search-overall-text-container">
            {{ total_num }} Result<span v-if="isPlural">s</span> for
            <span class="search-overall-search-input"> “{{ displaySearchInputText }}” </span>
          </span>
        </div>
        <span
          v-if="isFeaturedFilterEnabled && displayFilterPills && !isPartnerTenant"
          class="option-pill"
          :class="showFeaturedFilter ? 'active' : ''"
          @click="changeShowOnlyFilter('FEATURED')"
          @keyup.enter="changeShowOnlyFilter('FEATURED')"
          aria-label="Show only featured opportunities"
          role="option"
        >
          <i class="fa-sharp fa-solid fa-stars"></i>
          Featured
        </span>
        <span
          v-if="isFeaturedFilterEnabled && displayFilterPills && !isPartnerTenant"
          class="option-pill"
          :class="showOtherFilter ? 'active' : ''"
          @click="changeShowOnlyFilter('OTHER')"
          @keyup.enter="changeShowOnlyFilter('OTHER')"
          aria-label="Show only non featured opportunities"
          role="option"
        >
          Other Opportunities
        </span>
        <button
          class="collapse-filter-button partner"
          :class="!filterSectionCollapsed ? 'active' : ''"
          v-if="isPartnerTenant"
          style="margin-left: auto"
          aria-label="Toggle filter section"
          @click="toggleFilterSection"
        >
          <i class="fa-solid fa-filter"></i>
          Filters
        </button>
        <button
          v-else
          class="collapse-filter-button"
          :class="!filterSectionCollapsed ? 'active' : ''"
          style="margin-left: auto"
          aria-label="Toggle filter section"
          @click="toggleFilterSection"
        >
          <i class="fa-solid fa-filter-list"></i>
        </button>
      </div>
      <div class="filter-container content-filters-container" v-if="!filterSectionCollapsed">
        <multiselect-filter-component
          v-if="hasEventsAccess && hasProgramsAccess"
          placeholder="Any Type"
          filter_name="Events &amp; Program"
          style="margin: 0 !important"
          :options="type_of_entity"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="isPartnerTenant"
          placeholder="Any Career/Academic"
          filter_name="Career/Academic interests"
          style="margin: 0 !important"
          :options="filterData.career_academic_interests"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="isPartnerTenant"
          placeholder="Any Location"
          filter_name="Destination"
          style="margin: 0 !important"
          :options="filterData.countries"
          @filter-data="createFilterData"
        />

        <multiselect-filter-component
          placeholder="Any Focus Area"
          filter_name="Focus Areas"
          style="margin: 0 !important"
          :options="filterData.focusAreas"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!isPartnerTenant"
          placeholder="Any Department"
          filter_name="Departments"
          style="margin: 0 !important"
          :options="filterData.departments"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="isPartnerTenant"
          placeholder="Any Partner"
          filter_name="Partners"
          style="margin: 0 !important"
          :options="filterData.partners"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!isPartnerTenant"
          placeholder="Any Identity"
          filter_name="Identities"
          style="margin: 0 !important"
          :options="filterData.identities"
          @filter-data="createFilterData"
        />

        <multiselect-filter-component
          v-if="!isPartnerTenant"
          placeholder="Upcoming"
          filter_name="Upcoming &amp; past"
          style="margin: 0 !important"
          :options="status_options"
          @filter-data="createFilterData"
        />
      </div>
      <div v-if="showSearchOverallSection && !isMobile" class="search-overall-container">
        <button class="search-overall-back-button" @click="closeSearchResults" aria-label="Close search results">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <span class="search-overall-text-container">
          {{ total_num }} Result<span v-if="isPlural">s</span> for
          <span class="search-overall-search-input"> “{{ displaySearchInputText }}” </span>
        </span>
      </div>
      <!-- <div v-if="currentPage === 'browse' && showFeatured && featuredEvents.length > 0">
      <featured-events
        :featuredEvents="featuredEvents"
        @add-bookmark="handleBookmark"
        @remove-bookmark="handleBookmark"
        ref="fevents"
      />
    </div> -->

      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>
      <div
        @scroll="onScrollHandlerMobile"
        v-else-if="events.length > 0 && isPartnerTenant"
        class="events-content-container grid-wrapper-five-cards"
      >
        <partner-event-card
          v-for="(item, index) in events"
          :key="index"
          :item="item"
          @toggle-bookmark="handleBookmarkEvent"
        ></partner-event-card>
      </div>
      <div
        @scroll="onScrollHandlerMobile"
        v-else-if="events.length > 0 && !isFeaturedFilterEnabled && !isPartnerTenant"
        class="events-content-container grid-wrapper-five-cards"
      >
        <div class="eu-event-collection-card" v-for="item in events" :key="item.id" style="height: 360px">
          <card-component
            v-if="showItem(item)"
            :item="item"
            :is_attending="attending_event_ids.includes(item.id)"
            @handle-bookmark="handleBookmark"
            @change-attendance="changeAttendance"
          />
        </div>
      </div>
      <div
        @scroll="onScrollHandlerMobile"
        v-else-if="events.length > 0 && isFeaturedFilterEnabled && !isPartnerTenant"
        class="events-content-container grid-wrapper-five-cards"
      >
        <div class="eu-event-collection-card" v-for="item in filteredEvents" :key="item.id" style="height: 360px">
          <card-component
            v-if="showItem(item)"
            :item="item"
            :is_attending="attending_event_ids_live_list.includes(item.id)"
            @handle-bookmark="handleBookmark"
            @change-attendance="changeAttendance"
          />
        </div>
      </div>
      <div v-else>
        <div class="eu-empty-container" v-if="!isPartnerTenant">
          <div class="eu-empty">
            <div class="eu-empty-icon-container">
              <i class="fad fa-chalkboard-teacher eu-empty-icon"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <div v-if="currentPage === 'browse'">
                <span class="eu-empty-text">
                  No opportunities have been posted yet.
                  <br />
                  Check back later!
                </span>
              </div>
              <div v-else>
                <span class="eu-empty-text">You haven't saved any opportunities yet.</span>
                <button
                  type="button"
                  class="da-components-button da-btn-solid enup-demo-button"
                  style="width: fit-content; align-self: center; margin-top: 45px; width: 100%"
                  @click="changePage('browse')"
                  @keyup.enter="changePage('browse')"
                >
                  <span style="margin-left: 10px"> Browse </span><i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="eu-empty-container partner" v-else>
          <div class="eu-empty">
            <div class="eu-empty-icon-container partner">
              <i class="fad fa-calendar-star eu-empty-icon partner"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <div v-if="currentPage === 'browse'">
                <span class="eu-empty-text">
                  <span style="font-weight: 600"> No events or programs have been posted yet. </span>
                  <br />
                  Check back later!
                </span>
              </div>
              <div v-else>
                <span class="eu-empty-text">You haven't saved any opportunities yet.</span>
                <button
                  type="button"
                  class="da-components-button da-btn-solid enup-demo-button"
                  style="width: fit-content; align-self: center; margin-top: 45px; width: 100%"
                  @click="changePage('browse')"
                  @keyup.enter="changePage('browse')"
                >
                  <span style="margin-left: 10px"> Browse </span><i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>
<script lang="ts">
import '../utils/DateFormat';
import Cookies from 'js-cookie';
import { parseGETParamsIntoObject } from '../../utils/GETParametersUtil';
import EventsIndexService from '../services/EventsIndexService';
import EventList from './EventList.vue';
import FeaturedEvents from './FeaturedEvents.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import EventUtils from '../utils/EventUtils';
import MultiselectFilterComponent from '../../form/components/MultiselectFilterComponent.vue';
import CardComponent from './cards/CardComponent.vue';
import PartnerEventCard from './cards/PartnerEventCard.vue';
import RSVPService from '../services/RSVPService';
import PopupComponent from '../../components/popup/PopupComponent.vue';
import Notification from '../../components/Notification.vue';
import { isPartnerTenant } from '../../utils/CustomUtils';
import NavigationTabs from '../../components/NavigationTabs.vue';
import SearchBase from '../../components/formBase/SearchBase.vue';
import { useCommonDataStore } from '../../stores/commonData';
import CommonTitle from '../../components/CommonTitle.vue';
import { FEATURES, hasPermission, isFeatureEnabled, LICENSE_ONLY_FEATURES } from '../../utils/PermissionUtils';
export default {
  name: 'events-index',

  components: {
    EventList,
    FeaturedEvents,
    LoadingSpinner,
    MultiselectFilterComponent,
    CardComponent,
    PopupComponent,
    Notification,
    PartnerEventCard,
    NavigationTabs,
    SearchBase,
    CommonTitle,
  },

  props: [],

  computed: {
    createProgramLink() {
      return this.partner_name && this.partner_name !== 'None'
        ? `/programs/create?partner=${this.partner_name}`
        : '/programs/create';
    },
    createEventLink() {
      return this.partner_name && this.partner_name !== 'None'
        ? `/events/create/?partner=${this.partner_name}`
        : '/events/create/';
    },
    isPlural() {
      return this.total_num && this.total_num > 1;
    },
    showFeaturedFilter() {
      return this.showOnly == 'FEATURED';
    },
    showOtherFilter() {
      return this.showOnly == 'OTHER';
    },
    isMobile() {
      return window.innerWidth <= 576;
    },
    isFeaturedListEmpty() {
      const featuredEvents = this.events.filter((item) => this.isFeatured(item));
      return featuredEvents.length === 0;
    },
    isOtherListEmpty() {
      const otherEvents = this.events.filter((item) => !this.isFeatured(item));
      return otherEvents.length === 0;
    },
    displayFilterPills() {
      return !this.isFeaturedListEmpty && !this.isOtherListEmpty;
    },
    searchBackgroundImage() {
      return this.isPartnerTenant
        ? `${this.assetPath}assets/globalii-opportunities-landing-hero.jpg`
        : `${this.assetPath}assets/hero_baner_pm.png`;
    },
    searchSectionTitle() {
      return this.isPartnerTenant ? 'Explore opportunities' : 'Have something in mind?';
    },
    searchSectionDescription() {
      return this.isPartnerTenant
        ? 'Search events, programs, internships, scholarships, grad school, and jobs'
        : 'Find events, programs, opportunities, and more';
    },
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular');
    },
  },

  data() {
    return {
      events: [],
      commonDataStore: useCommonDataStore(),
      assetPath: process.env.MEDIA_URL,
      currentPage: 'browse',
      showFeatured: true,
      isLoading: true,
      isPageLoading: true,
      search_input: '',
      displaySearchInputText: '',
      pageCount: 1,
      filterData: null,
      status_options: [
        { name: 'Upcoming only', value: 'UPCOMING' },
        { name: 'Past', value: 'PAST' },
      ],
      type_of_entity: [
        { name: 'Programs', value: 'program' },
        { name: 'Events', value: 'event' },
      ],
      notification: { msg: '', isError: false, icon: null },
      isNotificationActive: false,
      params: {
        departments: [],
        countries: [],
        partners_name: [],
        is_online: [],
        focus_areas: [],
        status_of_event: ['UPCOMING'],
        saved: '',
        type: '',
      },
      isPartnerTenant: isPartnerTenant(),
      blockFetching: false,
      showOnly: 'FEATURED',
      filteredEvents: [],
      attending_event_ids_live_list: [],
      showSearchOverallSection: false,
      isFeaturedFilterEnabled: this.isMobile,
      filterSectionCollapsed: true,
      total_num: 0,
      isManager: null,
      canManage: null,
      areDeptsEnabled: false,
      partner_name: '',
      getEventsMethod: 'getEvents',
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      navigationItems: [
        {
          label: 'Browse Opportunities',
          routeName: 'browse',
          isRedirectUrl: false,
        },
        {
          label: 'Saved/Upcoming',
          routeName: 'saved',
          isRedirectUrl: false,
        },
      ],
    };
  },

  async created() {
    this.hasEventsAccess = hasPermission(FEATURES.EVENTS);
    this.hasProgramsAccess = hasPermission(FEATURES.PROGRAMS);
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS);
    this.getEventsMethod = this.isPartnerTenant ? 'getEventsDabi' : 'getEvents';
    this.eventsService = new EventsIndexService();
    const params = parseGETParamsIntoObject();
    if (params.hasOwnProperty('page') && params['page'] === 'saved') {
      await this.changePage('saved');
    }
    await this.getPreData();
    if (this.isPartnerTenant) {
      this.type_of_entity = [
        { name: 'Events', value: 'event' },
        { name: 'Graduate School', value: 'Graduate School' },
        { name: 'Internships & Jobs', value: 'Internships & Jobs' },
        { name: 'Global Programs', value: 'Global Programs' },
        { name: 'Scholarships', value: 'Scholarships' },
        { name: 'Professional/Leadership Programs', value: 'Professional/Leadership Programs' },
      ];
    }
    if (this.canManage) {
      this.navigationItems = [
        {
          label: 'Browse Opportunities',
          routeName: 'browse',
          isRedirectUrl: false,
        },
        {
          label: 'Manage Opportunities',
          routeName: 'manage',
          isRedirectUrl: false,
        },
        {
          label: 'Saved/Upcoming',
          routeName: 'saved',
          isRedirectUrl: false,
        },
      ];
      if (this.isPartnerTenant) {
        this.navigationItems.push({
          label: 'Manage Leads',
          routeName: 'leads',
          isRedirectUrl: false,
        });
      }
    }

    this.eventUtils = new EventUtils(this.eventsService, this.headers);
    this.rsvpService = new RSVPService();

    this.attending_event_ids_live_list = this.attending_event_ids;
    if (this.currentPage !== 'saved') {
      this.fetchEvents();
    }
    this.scroll();
  },

  methods: {
    async getPreData() {
      this.isPageLoading = true;
      const res = await this.eventsService.getPredata();
      if (res) {
        this.filterData = this.commonDataStore.getCommonData;
        this.filterData.partners = res.partners;
        this.filterData.career_academic_interests = this.filterData.cae;
        this.canManage = res.can_manage;
        this.attending_event_ids = res.attending_event_ids;
        this.isManager = res.is_manager;
        this.partner_name = res.partner_name;
        this.featuredEvents = res.featured_events;
        if (this.isPartnerTenant) {
          this.filterData.countries = [
            { name: 'Virtual/Hybrid', type: 'destinations' },
            ...this.filterData.focus_areas.filter((fa) => fa.type === 'destinations'),
          ];
          this.filterData.focusAreas = this.filterData.focus_areas.filter((fa) => fa.type !== 'destinations');
        } else {
          this.filterData.countries = res.countries;
          this.filterData.focusAreas = this.filterData.focus_areas;
        }
      }
      this.isPageLoading = false;
    },
    search(input_text) {
      this.search_input = input_text;
      this.fetchEvents();
    },
    toggleFilterSection() {
      if (this.filterSectionCollapsed) {
        this.openFilterSection();
      } else {
        this.closeFilterSection();
      }
    },
    openFilterSection() {
      this.filterSectionCollapsed = false;
    },
    closeFilterSection() {
      this.filterSectionCollapsed = true;
    },
    openSearchResults() {
      this.displaySearchInputText = this.search_input;
      this.showSearchOverallSection = true;
      this.isFeaturedFilterEnabled = false;
    },
    closeSearchResults(fetch_after = true) {
      this.showSearchOverallSection = false;
      this.search_input = '';
      this.displaySearchInputText = '';
      this.isFeaturedFilterEnabled = this.isMobile;
      if (fetch_after) this.fetchEvents();
    },
    showItem(item) {
      return (
        !this.isFeaturedFilterEnabled ||
        (this.showFeaturedFilter && this.isFeatured(item)) ||
        (this.showOtherFilter && !this.isFeatured(item))
      );
    },
    isFeatured(item) {
      return item.is_featured || item.is_featured_for_audience;
    },
    filterByFeatured() {
      if (!this.isFeaturedFilterEnabled) {
        this.filteredEvents = this.events;
      }

      if (this.showFeaturedFilter) {
        this.filteredEvents = this.events.filter((item) => this.isFeatured(item));
      }

      if (this.showOtherFilter) {
        this.filteredEvents = this.events.filter((item) => !this.isFeatured(item));
      }
    },
    changeShowOnlyFilter(showOnly) {
      this.showOnly = showOnly;
      this.filterByFeatured();
    },
    closeNotification() {
      this.notification = { msg: '', isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = { ...notification };
      this.isNotificationActive = true;
    },
    handleBookmarkEvent(item) {
      this.handleBookmark(item.id, item.is_bookmarked ? 'remove' : 'add');
      item.is_bookmarked = !item.is_bookmarked;
    },
    async handleBookmark(itemId, action: string): Promise<void> {
      const res = await this.eventUtils.handleBookmark(itemId, action);
      const notification = { msg: '', isError: false, icon: null };
      if (res.success) {
        notification.msg = `Bookmark successfully ${action == 'add' ? 'added' : 'removed'}`;
        notification.icon = 'fad fa-check-circle';
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = 'fad fa-exclamation-circle';
      }
      this.showNotification(notification);
    },
    async fetchEvents() {
      this.pageCount = 1;

      if (this.search_input === '') {
        this.showFeatured = true;
        this.closeSearchResults(false);
      } else {
        this.showFeatured = false;
      }

      this.isLoading = true;
      const allParams = { ...this.params, focus_areas: [...this.params.focus_areas, ...this.params.countries] };
      const response = await this.eventsService[this.getEventsMethod]({
        ...allParams,
        q: encodeURIComponent(this.search_input),
        page: this.pageCount,
      });
      if (response) {
        this.events = response.opportunities;
        this.events.sort((a, b) => b.is_featured_for_audience - a.is_featured_for_audience); // put featured audiences on top
        this.total_num = response.total_num;

        if (this.search_input != '' && !this.initialLoad) {
          this.openSearchResults();
        }
      }
      this.isLoading = false;
      this.filterByFeatured();
      if (this.isFeaturedListEmpty) {
        this.changeShowOnlyFilter('OTHER');
      }
    },

    async changePage(page: string) {
      if (this.currentPage !== page) {
        if (page === 'manage') {
          this.$router.push('/opportunities/manage');
        }

        if (page === 'leads') {
          this.$router.push('/opportunities/leads');
        }

        this.pageCount = 1;

        if (page === 'browse') {
          this.params.saved = '';
          this.showFeatured = true;

          this.isLoading = true;
        } else if (page === 'saved') {
          this.params.saved = 'saved';

          this.isLoading = true;
        }
        const allParams = { ...this.params, focus_areas: [...this.params.focus_areas, ...this.params.countries] };
        const response = await this.eventsService[this.getEventsMethod]({ ...allParams });
        if (response) {
          this.events = response.opportunities;
          this.events.sort((a, b) => b.is_featured_for_audience - a.is_featured_for_audience); // put featured audiences on top
          this.isLoading = false;
          this.filterByFeatured();
          this.closeSearchResults(false);
        }

        this.currentPage = page;
      }
      if (this.isFeaturedListEmpty) {
        this.changeShowOnlyFilter('OTHER');
      }
    },
    async onScrollHandlerMobile(event) {
      if (window.innerWidth <= 576) {
        const opportunities_container = event.target;
        const newScrollLeft = opportunities_container.scrollLeft;
        const width = opportunities_container.offsetWidth;
        const scrollWidth = opportunities_container.scrollWidth;
        var offset = 150;

        if (
          scrollWidth - newScrollLeft - width < offset &&
          !this.isLoading &&
          this.pageCount !== 0 &&
          !this.blockFetching
        ) {
          this.blockFetching = true;
          this.pageCount += 1;
          const allParams = { ...this.params, focus_areas: [...this.params.focus_areas, ...this.params.countries] };
          const response = await this.eventsService[this.getEventsMethod]({
            ...allParams,
            q: this.search_input,
            page: this.pageCount,
          });

          let newEvents = [];

          if (response) {
            newEvents = response.opportunities;
          }

          if (newEvents && newEvents.length > 0) {
            newEvents.forEach((e) => {
              this.events.push(e);
            });
            this.filterByFeatured();
          } else this.pageCount = 0;
          this.blockFetching = false;

          if (this.isFeaturedListEmpty) {
            this.changeShowOnlyFilter('OTHER');
          }
        }
      }
    },
    async onScrollHandlerDesktop() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;
      if (bottomOfWindow && this.pageCount !== 0) {
        this.pageCount += 1;
        const allParams = { ...this.params, focus_areas: [...this.params.focus_areas, ...this.params.countries] };
        const response = await this.eventsService[this.getEventsMethod]({
          ...allParams,
          q: this.search_input,
          page: this.pageCount,
        });

        let newEvents = [];

        if (response && response.opportunities) {
          newEvents = response.opportunities;
        }

        if (newEvents && newEvents.length > 0) {
          newEvents.forEach((e) => {
            this.events.push(e);
          });
        } else this.pageCount = 0;

        if (this.isFeaturedListEmpty) {
          this.changeShowOnlyFilter('OTHER');
        }
      }
    },
    async scroll() {
      window.onscroll = async () => {
        if (window.innerWidth > 576) {
          this.onScrollHandlerDesktop();
        }
      };
    },
    createFilterData(value, name) {
      if (name === 'Departments') {
        this.params.departments = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Partners') {
        this.params.partners_name = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Focus Areas') {
        this.params.focus_areas = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Identities') {
        this.params.identities = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Upcoming & past') {
        this.params.status_of_event = value.map((v) => encodeURIComponent(v.value));
      }
      if (name === 'Virtual/In Person') {
        this.params.is_online = value.map((v) => encodeURIComponent(v.value));
      }
      if (name === 'Events & Program') {
        this.params.type = value.map((v) => encodeURIComponent(v.value));
      }
      if (name === 'Career/Academic interests') {
        this.params.career_academic_interests = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Destination') {
        this.params.countries = value.map((v) => encodeURIComponent(v.name));
      }
      this.fetchEvents();
    },

    async changeAttendance(id): Promise<void> {
      let isAttending = false;
      if (this.attending_event_ids_live_list.includes(id)) {
        isAttending = true;
        this.attending_event_ids_live_list = this.attending_event_ids_live_list.filter((list_id) => list_id != id);
      } else {
        this.attending_event_ids_live_list.push(id);
      }

      const payload = {
        target: id,
        isAttending: !isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);
      if (res.success) {
        this.notification.msg = 'Successfully updated event';
        this.notification.icon = 'fad fa-check-circle';
      } else {
        this.notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        this.notification.isError = true;
        this.notification.icon = 'fad fa-exclamation-circle';

        if (this.attending_event_ids_live_list.includes(id)) {
          this.attending_event_ids_live_list = this.attending_event_ids_live_list.filter((list_id) => list_id != id);
        } else {
          this.attending_event_ids_live_list.push(id);
        }
      }
      this.showNotification(this.notification);
    },
  },
};
</script>

<style scoped lang="scss">
.title-wrapper {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
}
.mobile-options {
  width: 100%;
  display: flex;
  gap: 8px;
}
.collapse-filter-button.partner {
  width: 100%;
  padding: 0.75rem 0;
  border: 1px solid #c3cbd6;
  border-radius: 24px;
  background-color: #fff;
}
.partner-nav-container {
  padding: 0;
}

@media screen and (max-width: 576px) {
  .partner-nav-container {
    padding: 0 1.5rem;
  }
  .title-wrapper {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  }
}
</style>
