<template>
  <div class="department-event-container">
    <div class="event-search-manage-container">
      <div class="eu-search">
        <form action="" @submit.prevent="fetchEvents()" class="eu-search-form" style="margin-top: 50px" role="search">
          <input
            v-model="search_input"
            name="q"
            type="search"
            placeholder="Search for opportunities"
            class="eu-search-input"
          />
          <button class="eu-search-btn-icon" aria-label="search">
            <i class="eu-search-icon fas fa-search"></i>
          </button>
        </form>
      </div>
      <div v-if="canManage()" style="min-width: 116px; position: relative; top: 13px; white-space: nowrap">
        <popup-component buttonText="Create">
          <template #items>
            <a
              class="eu-resource-burger-el"
              aria-label="Go to events creation page"
              @click="setRedirectPage"
              @keyup.enter="setRedirectPage"
              :href="`/events/create/${
                isGroupTab && group.name
                  ? '?group=' + encodeURIComponent(group.name)
                  : isPartnerTab && partner.name
                  ? '?partner=' + encodeURIComponent(partner.name)
                  : ''
              }`"
            >
              <i class="fa-solid fa-calendar-pen" style="padding-right: 5px"></i>
              <span>Event</span>
            </a>
            <a
              class="eu-resource-burger-el"
              aria-label="Go to program creation page"
              @click="setRedirectPage"
              @keyup.enter="setRedirectPage"
              :href="`/programs/create${
                isGroupTab && group.name
                  ? '?group=' + encodeURIComponent(group.name)
                  : isPartnerTab && partner.name
                  ? '?partner=' + encodeURIComponent(partner.name)
                  : ''
              }`"
            >
              <i class="fa-solid fa-building-columns" style="padding-right: 5px"></i>
              <span>Program</span>
            </a>
          </template>
        </popup-component>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <div class="eu-empty-container" :class="isPartnerTenant ? 'partner' : ''" v-if="!isLoading && events.length === 0">
      <div class="eu-empty">
        <div class="eu-empty-icon-container" :class="isPartnerTenant ? 'partner' : ''">
          <i class="fad fa-calendar eu-empty-icon" :class="isPartnerTenant ? 'partner' : ''"></i>
        </div>
        <div style="display: flex; flex-direction: column">
          <span class="eu-empty-text">
            No events have been posted yet.
            <br />
            Check back later!
          </span>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <div v-else-if="events.length > 0" class="grid-wrapper-four-card">
      <div class="eu-event-collection-card" v-for="item in events" :key="item.id" style="height: 360px">
        <card-component
          :item="item"
          :is_attending="attending_event_ids.includes(item.id)"
          :is_partner_page="isPartnerTab"
          :can_manage="canManage"
          @handle-bookmark="handleBookmark"
          @change-attendance="changeAttendance"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as Cookies from 'js-cookie';
import EventsIndexService from '../../events/services/EventsIndexService';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import RSVPService from '../../events/services/RSVPService';
import CardComponent from '../../events/components/cards/CardComponent.vue';
import PopupComponent from '../../components/popup/PopupComponent.vue';
import EventUtils from '../../events/utils/EventUtils';
import { isPartnerTenant } from "../../utils/CustomUtils";

export default {
  components: { LoadingSpinner, CardComponent, PopupComponent },
  name: 'events-tab',
  props: ['department', 'group', 'partner', 'loggedUser', 'isGroupTab', 'isPartnerTab'],

  data() {
    return {
      search_input: '',
      isLoading: true,
      events: [],
      attending_event_ids: [],
      params: {
        departments: [],
        is_online: '',
        focus_areas: [],
        identities: [],
        status_of_event: 'UPCOMING',
        saved: '',
        group_name: '',
        partner_name: '',
      },
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    this.params.departments = this.department ? [encodeURIComponent(this.department.name)] : [];
    this.params.identities = this.group ? this.group.identities.map((e) => encodeURIComponent(e.name)) : [];
    this.params.focus_areas = this.group ? this.group.focus_areas.map((e) => encodeURIComponent(e.name)) : [];
    this.params.group_name = this.group ? encodeURIComponent(this.group.name) : '';
    this.params.partner_name = this.partner ? encodeURIComponent(this.partner.name) : '';
    this.eventsService = new EventsIndexService();
    this.rsvpService = new RSVPService();
    this.eventUtils = new EventUtils(this.eventsService, this.headers);
    this.fetchEvents();
    this.getEventsIdsUserAttending();
  },
  methods: {
    isPartnerTenant,
    async fetchEvents() {
      const res = await this.eventsService.getEvents({ ...this.params, q: encodeURIComponent(this.search_input) });
      this.events = res.opportunities;
      this.isLoading = false;
    },
    async getEventsIdsUserAttending() {
      this.attending_event_ids = await this.rsvpService.getEventsIdsUserAttending(this.loggedUser.id);
    },
    async changeAttendance(id) {
      let isAttending = false;
      if (this.attending_event_ids.includes(id)) {
        isAttending = true;
      }

      const payload = {
        target: id,
        isAttending: !isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);
    },
    handleBookmark(id, action) {
      this.eventUtils.handleBookmark(id, action);
    },
    setRedirectPage() {
      sessionStorage.setItem('redirectTo', window.location.href);
      if (this.isGroupTab && this.group.name) {
        sessionStorage.setItem('groupName', this.group.name);
      }
      if (this.isPartnerTab && this.partner.name) {
        sessionStorage.setItem('partnerName', this.partner.name);
      }
    },
    canManage() {
      if (this.isGroupTab && this.group.name) {
        return this.loggedUser.has_event_access && this.loggedUser?.userManageGroups.includes(this.group.name);
      } else if (this.isPartnerTab && this.partner.name) {
        return this.loggedUser.has_event_managing_access || this.loggedUser?.partnerAdmins.includes(this.partner.name);
      } else {
        return this.loggedUser.has_event_managing_access;
      }
    },
  },
};
</script>
