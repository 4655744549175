<template>
  <div class="partner-tenant-articles-and-resources-container">
    <div class="title-container">
      <h3 class="text">
        <i class="fa-regular fa-newspaper icon"></i>
        Articles &amp; Resources
      </h3>
      <router-link class="dabi-main-button-outlined see-all-button" to="/library" aria-label="Go to resources page">
        See all
      </router-link>
    </div>
    <div class="content-container">
      <button
        class="left-arrow arrow-button"
        aria-label="Show previous page"
        @click="showPreviousPage"
        @keyup.enter="showPreviousPage"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        class="right-arrow arrow-button"
        aria-label="Show next page"
        @click="showNextPage"
        @keyup.enter="showNextPage"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <div
        v-for="(item, index) in paginate(resourcesList, pageSize, activePage)"
        :key="index"
        :class="isTopCell(index) ? 'top-cell' : 'bottom-cell'"
        class="content-cell"
      >
        <img
          v-if="cardImageUrl(item)"
          :src="cardImageUrl(item)"
          alt=""
          class="image"
          @click="$router.push(`/library?resource_id=${item.id}`)"
          :aria-label="`Open ${item.name} in modal`"
        />
        <span
          tabindex="0"
          v-else
          @click="$router.push(`/library?resource_id=${item.id}`)"
          :aria-label="`Open ${item.name} in modal`"
          aria-haspopup="dialog"
          role="button"
          class="resource-icon-wrapper"
        >
          <i :class="cardFaIconClass(item)" class="resource-icon"></i>
        </span>

        <div class="text-container">
          <router-link :to="`/library?resource_id=${item.id}`" class="title text-clamp-2">
            <img :src="cardImageUrl(item)" alt="" class="image mobile" />
            {{ item.name }}
          </router-link>
          <div class="description text-clamp-2">
            {{ item.description }}
          </div>
          <div class="tags-container">
            <div class="tag">{{ getResourceTag(item) }}</div>
          </div>
          <div class="author-container">
            <div class="info-wrapper">
              <img v-if="item.partner_logo" :src="item.partner_logo" alt="Partner logo" class="author-image" />
              <span v-if="item.partner_name" class="author-name">{{ item.partner_name }}</span>
              <span class="dot">•</span>
              <span class="date">{{ formatDate(item.timestamp) }}</span>
            </div>
            <button
              class="dabi-bookmark-button"
              :class="isResourceBookmarked(item.id) ? 'active' : ''"
              @click="toggleBookmark(item)"
              :aria-label="`Toggle bookmark for resource ${item.name}`"
            >
              <i class="fa-bookmark" :class="isResourceBookmarked(item.id) ? 'fa-solid' : 'fa-regular'"></i>
            </button>
          </div>
        </div>
      </div>
      <resource-card
        v-for="(item, index) in paginate(resourcesList, pageSize, activePage)"
        :key="`mobile-${index}`"
        :item="item"
        class="mobile-resource-card"
      />
    </div>
    <div class="actions-container">
      <slider-dots
        ref="recommendedResourcesSlider"
        :totalNo="getPagesCount(resourcesList, pageSize)"
        :initialSelectedNo="1"
        @changeSelected="changeDisplayedPageHandler"
      />
      <router-link
        class="dabi-main-button-outlined see-all-button mobile"
        to="/library"
        aria-label="Go to resources page"
      >
        See all
      </router-link>
    </div>
  </div>
</template>
<script>
import DashboardService from '../../services/DashboardService';
import BookmarkService from '../../../bookmarks/services/BookmarkService';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';
import moment from 'moment';
import * as Cookies from 'js-cookie';
import ResourceCard from '../../../library/components/ResourceCard.vue';

export default {
  name: 'partner-tenant-articles-and-resources',
  components: {
    SliderDots,
    ResourceCard,
  },
  props: [],
  data() {
    return {
      dashboardService: new DashboardService(),
      bookmarkService: new BookmarkService(),
      assetPath: process.env.MEDIA_URL + 'assets/default-header.webp',
      resourcesList: [],
      bookmarkedResources: [],
      activePage: 1,
      pageSize: 4,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    this.dashboardService.getDashboardData({ section: 'recommendedResources', limit: 16 }).then((data) => {
      this.resourcesList = data ? data : [];
      this.emitIfEmpty();
    });
    this.bookmarkService.getBookmarkedResources({ limit: 999, ids_only: true }).then((data) => {
      this.bookmarkedResources = data.resources;
    });
    if (window.screen.width <= 720) {
      this.pageSize = 999;
    }
  },
  methods: {
    cardImageUrl(item) {
      if (item.resource_type === 'IMG') {
        return item.resource_url;
      }
      if (item.thumbnail_url) {
        return item.thumbnail_url;
      }
      return null;
    },
    cardFaIconClass(item) {
      if (item.resource_type === 'DOC') {
        return 'fa-regular fa-file-alt';
      }
      if (item.resource_type === 'VID') {
        return 'far fa-file-video';
      }
      if (item.resource_type === 'URL') {
        return 'far fa-external-link';
      }
      if (item.resource_type === 'URL_INTERNAL') {
        return 'far fa-external-link';
      }
      if (item.resource_type === 'URL_EXTERNAL') {
        return 'far fa-external-link';
      }
    },
    emitIfEmpty() {
      if (!this.resourcesList || this.resourcesList.length === 0) {
        this.$emit('is-empty');
      }
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    getPagesCount(array, page_size = 4) {
      let result = Math.floor(array.length / page_size);
      if (array.length % page_size === 0) {
        return result;
      }
      return result + 1;
    },
    changeDisplayedPageHandler(pageNo) {
      this.changePage(pageNo);
    },
    changePage(pageNo) {
      this.activePage = pageNo;
    },
    showPreviousPage() {
      this.$refs.recommendedResourcesSlider.selectPrevious();
    },
    showNextPage() {
      this.$refs.recommendedResourcesSlider.selectNext();
    },
    isResourceBookmarked(resourceId) {
      return this.bookmarkedResources.includes(resourceId);
    },
    isTopCell(index) {
      return index % 4 < 2;
    },
    formatDate(dateString) {
      return moment(dateString).format('MMMM DD, YYYY');
    },
    getResourceTag(item) {
      if (item.resource_type === 'IMG') {
        return 'Image';
      }
      if (item.resource_type === 'VID') {
        return 'Video';
      }
      if (item.resource_type.includes('URL')) {
        return 'URL';
      }

      return 'Resource';
    },
    getResourceImage(item) {
      if (item.resource_type === 'IMG') {
        return item.resource_url;
      }

      return this.assetPath;
    },
    toggleBookmark(item) {
      if (item.is_bookmarked) {
        const payload = {
          target: item.id,
        };
        this.bookmarkService.deleteBookmarkedResources(payload, this.headers);
        let index = this.bookmarkedResources.indexOf(item.id);

        if (index !== -1) {
          this.bookmarkedResources.splice(index, 1);
        }
      } else {
        const payload = {
          target: item.id,
        };
        this.bookmarkService.postBookmarkResources(payload, this.headers);
        this.bookmarkedResources.push(item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-icon-wrapper {
  position: relative;
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 16px;
  background-color: #f1f1f9;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .resource-icon {
    font-size: 54px;
    color: #00a1b3;
    opacity: 0.25;
    transition: opacity 0.3s;
  }

  &:hover {
    cursor: pointer;

    .resource-icon {
      opacity: 0.75;
    }
  }
}
</style>
