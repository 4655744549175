<template>
  <div class="content-cell">
    <span class="label">{{ labelText }}</span>
    <button
      class="dabi-bookmark-button"
      :class="item.is_bookmarked ? 'active' : ''"
      @click="toggleBookmark()"
      :aria-label="`${item.is_bookmarked ? 'Remove' : 'Add'} bookmark`"
    >
      <i v-if="item.is_bookmarked" class="fa-solid fa-bookmark"></i>
      <i v-else class="fa-regular fa-bookmark"></i>
    </button>
    <img :src="item.thumbnail" alt="Event cover image" class="image" />
    <router-link class="title" :to="`/${item.slug}`" aria-label="Visit opportunity page">
      <i v-if="item.is_featured" class="fa-solid fa-stars"></i>
      {{ item.title }}
    </router-link>
    <div v-if="!item.is_hybrid && (item.is_online || item.location)" class="location">
      <span v-if="item.is_online">
        <i class="fa-solid fa-desktop"></i>
        Online
      </span>
      <span v-else>
        <i class="fa-solid fa-location-dot"></i>
        {{ item.location }}
      </span>
    </div>
    <div v-if="item.is_hybrid" class="location">
      <span>
        <i class="fa-solid fa-desktop"></i>
        <i class="fa-solid fa-location-dot"></i>
        Hybrid
      </span>
    </div>
    <div
      v-if="item.partner"
      class="partner-container"
      tabindex="0"
      @click="goToPartnersPage"
      @keyup.enter="goToPartnersPage"
    >
      <img v-if="item.partner.logo_url" class="logo" :src="item.partner.logo_url" :alt="item.partner.name" />
      <span class="name">{{ item.partner.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partner-event-card',
  props: ['item'],
  computed: {
    labelText() {
      if (this.item.type_of_program_management == 'event') {
        return 'Event';
      }

      if (this.item.program_type) {
        return this.item.program_type;
      }

      if (this.item.type) {
        return this.item.type;
      }

      return 'Program';
    },
  },
  methods: {
    toggleBookmark() {
      this.$emit('toggle-bookmark', this.item);
    },
    goToPartnersPage() {
      this.$router.push(`/partners/${encodeURIComponent(this.item.partner.name)}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-cell {
  height: 290px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  z-index: 8;

  .label {
    position: absolute;
    top: 1rem;
    left: 1rem;

    padding: 0.063rem 0.5rem 0.188rem;
    border-radius: 16px;
    background-color: #f2f5f7;
    color: #516075;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .dabi-bookmark-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .image {
    height: 10rem;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    flex-shrink: 0;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    height: 40px;
    color: #444;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    i {
      color: #22a5a0;
    }
  }

  .location {
    color: #95919b;
    font-size: 12px;
    font-weight: 600;
  }

  .partner-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .logo {
      border: 1px solid #d8dce5;
      border-radius: 16px;
      width: 1.5rem;
      height: 1.5rem;
      object-fit: cover;
      object-position: center;
    }

    .name {
      color: #444444;
      font-size: 0.875rem;
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;

      .name {
        color: #666;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content-cell {
    .image {
      width: 15rem;
    }
  }
}
</style>
