import HttpService from '../../services/HttpService';

export default class AccountService extends HttpService {
  constructor() {
    super('/accounts');
  }

  resendValidationEmail(data, customHeaders, throwError=false) {
    this.endpoint = '/accounts/api/send_validation_email';
    return this.post(data, customHeaders, throwError);
  }
  verifyGoogleToken(data, customHeaders, throwError=false) {
    this.endpoint = '/channels/api/social/google/verify';
    return this.post(data, customHeaders, throwError);
  }
  loginWithGoogleToken(data, customHeaders, throwError=false) {
    this.endpoint = '/channels/api/social/google/login';
    return this.post(data, customHeaders, throwError);
  }
  registerAndLoginWithGoogleToken(data, customHeaders, throwError=false) {
    this.endpoint = '/channels/api/social/google/login';
    return this.post(data, customHeaders, throwError);
  }
  sendResetPasswordEmail(data, customHeaders, throwError=false) {
    this.endpoint = '/accounts/api/send_reset_password_mail';
    return this.postFormData(data, customHeaders, throwError);
  }
}
