<template>
  <section
    class="blog-article-section"
    :class="!isUserLoggedIn ? 'logout-blog-section' : 'login-blog-section'"
    v-if="blogDisplay"
  >
    <div class="cover-image-container" :class="isUserLoggedIn ? 'border-radius' : 'full-w'">
      <img :src="blogDisplay.image.url" :alt="blogDisplay.image.alt" />
      <div class="tint"></div>
      <div class="back-button-container">
        <button
          v-if="isUserLoggedIn"
          @click="$router.push('/library')"
          aria-label="Go back to community library"
          class="back-button"
        >
          <i class="fa-solid fa-chevron-left back-arrow"></i>
          <span class="text"> Back to library</span>
        </button>
      </div>
      <span class="blog-pill">Blog</span>
      <h1 class="blog-article-body-title blog-article-content-block-medium in-cover-title">
        {{ blogDisplay.title }}
      </h1>
    </div>
    <breadcrumbs
      v-if="!disableBreadcrumbs"
      :currentPageLabel="blogDisplay.title"
      :routeItems="breadcrumbsLinkItems"
      style="width: 100%"
    />
    <div class="blog-article" :style="isLoading ? 'padding-top: 80px;' : 'padding-top: 0;'">
      <div class="blog-article-header">
        <div class="iu-blog-article-info-list">
          <div class="iu-blog-article-header-author-container">
            By <b>{{ blogDisplay.author.name }}</b>
          </div>
          <div class="dot-separator">•</div>
          <div class="iu-blog-article-date-container">
            {{ formatDate(blogDisplay.createdAt) }}
          </div>
          <div class="dot-separator">•</div>
          <div class="iu-blog-article-categories-container">
            <div v-for="(tag, index) in blogDisplay.tag" :key="index" class="iu-blog-article-category-item">
              {{ tag.tagText }}
            </div>
          </div>
        </div>
      </div>
      <div class="blog-article-body">
        <!-- <div class="blog-article-share-section">
          Share this on
          <a
            :href="'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl"
            target="_blank"
            class="fab fa-facebook-square fb-sha"
            style="color: #3b5998; font-size: 21px; margin: 0 8px 0 16px"
          ></a>
          <a
            :href="'https://twitter.com/intent/tweet?text=' + pageUrl"
            target="_blank"
            class="fab fa-twitter"
            style="color: #00acee; font-size: 21px; margin: 0 8px"
          ></a>
          <a
            :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + pageUrl"
            target="_blank"
            class="fab fa-linkedin"
            style="color: #0e76a8; font-size: 21px; margin: 0 8px"
          ></a>
        </div> -->
        <span v-for="(block, index) in blogDisplay.blogContent" :key="index" style="display: contents">
          <h2
            v-if="block.blockType == 'subheading'"
            class="blog-article-body-subtitle blog-article-content-block-medium"
          >
            {{ block.subheadingText }}
          </h2>
          <div
            v-if="block.blockType == 'paragraph'"
            class="blog-article-body-description blog-article-content-block-medium"
            v-html="block.parsedHtml"
          ></div>
          <div
            v-if="block.blockType == 'quote'"
            class="blog-article-body-description blog-article-content-block-medium"
          >
            <p class="quote-text-container" style="white-space: pre-line">{{ block.quoteText }}</p>
          </div>
          <div v-if="block.blockType == 'list'" class="blog-article-body-description blog-article-content-block-medium">
            <ul v-if="block.listType == 'unordered'" class="blog-list">
              <li v-for="(item, index) in block.listItems" :key="index">
                {{ item.listItemText }}
              </li>
            </ul>
            <ol v-if="block.listType == 'ordered'" class="blog-list">
              <li v-for="(item, index) in block.listItems" :key="index">
                {{ item.listItemText }}
              </li>
            </ol>
          </div>
          <div v-if="block.blockType == 'image'" class="blog-article-body-image-container">
            <img :src="block.image.url" :alt="block.image.alt" class="blog-article-body-image" />
          </div>
        </span>
        <div class="blog-article-body-description bottom-line"></div>
        <div class="blog-article-share-section">
          Share this on
          <a
            :href="'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl"
            target="_blank"
            class="fab fa-facebook-square fb-sha"
            style="color: #3b5998; font-size: 21px; margin: 0 8px 0 16px"
          ></a>
          <a
            :href="'https://twitter.com/intent/tweet?text=' + pageUrl"
            target="_blank"
            class="fab fa-twitter"
            style="color: #00acee; font-size: 21px; margin: 0 8px"
          ></a>
          <a
            :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + pageUrl"
            target="_blank"
            class="fab fa-linkedin"
            style="color: #0e76a8; font-size: 21px; margin: 0 8px"
          ></a>
        </div>
        <div class="blog-article-author-section">
          <div class="blog-article-author-card">
            <img class="author-image" :alt="blogDisplay.author.name" :src="authorProfilePictureUrl" />
            <div class="text-container">
              <span class="blog-article-author-bio-title">Written by {{ blogDisplay.author.name }}</span>
              <span class="blog-article-author-bio-subtitle">{{ blogDisplay.author.position }}</span>
              <span class="blog-article-author-bio-desc" v-if="blogDisplay.author.bio">
                {{ blogDisplay.author.bio }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <loading-spinner :isPartnerTenantStyle="true" wrapperClass="wrapper" wrapperStyle="height: calc(100vh - 362px);" />
  </section>
</template>

<script>
import moment from 'moment';
import Breadcrumbs from '../../partners/components/landing_pages/components/Breadcrumbs.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import useBlogManager from '../composables/useBlogManager';
import { defineComponent } from '@vue/composition-api';
import { getBlogAuthorProfilePictureUrl, getMetaConfig, getRouteMetaData } from '../../partners/utils';

export default defineComponent({
  name: 'blog-details',
  setup(props, context) {
    const { blog: blogDisplay, isLoading, fetchBlog } = useBlogManager.setup({}, context);

    return { blogDisplay, isLoading, fetchBlog };
  },
  props: ['blog', 'slug', 'disableBreadcrumbs', 'isUserLoggedIn'],
  components: {
    Breadcrumbs,
    LoadingSpinner,
  },
  computed: {
    pageUrl() {
      if (this.blogDisplay && this.blogDisplay.urlSlug) {
        return `${window.location.origin}/articles/${this.blogDisplay.urlSlug}`;
      }
      return window.location.href;
    },
    isFetchBlog() {
      return !!!this.blog;
    },
    authorProfilePictureUrl() {
      return getBlogAuthorProfilePictureUrl(this.blogDisplay.author);
    },
  },
  watch: {
    isLoading(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.$Progress.start();
      }
      if (oldVal === true && newVal === false && this.blogDisplay) {
        this.$Progress.finish();
      }
      if (oldVal === true && newVal === false && !this.blogDisplay) {
        this.$Progress.fail();
      }
    },
    blogDisplay(blog) {
      const metaConfig = getMetaConfig();
      const routeMetaData = getRouteMetaData(metaConfig, this.$route.meta.metaConfigUrlKey);

      if (routeMetaData) {
        let titleBuilder = [blog.title, metaConfig.app_name];

        if (routeMetaData.title) {
          titleBuilder = [this.blogDisplay.title, routeMetaData.title, metaConfig.app_name];
        }

        document.title = titleBuilder.join(` ${metaConfig.separator} `);
      }
    },
  },
  async created() {
    this.determinateLinks();

    if (this.isFetchBlog && this.slug) {
      await this.fetchBlog(this.slug);
    } else {
      this.blogDisplay = this.blog;
    }
  },
  data() {
    return {
      breadcrumbsLinkItems: [],
    };
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('MMMM DD, YYYY');
    },
    determinateLinks() {
      this.breadcrumbsLinkItems = [
        {
          label: 'Home',
          path: '/',
          isSpa: this.isFetchBlog,
        },
        {
          label: this.isFetchBlog ? 'Articles & Resources' : 'Blog',
          path: this.isFetchBlog ? '/articles' : '/blog',
          isSpa: this.isFetchBlog,
        },
      ];
    },
  },
});
</script>
<style lang="scss" scoped>
.login-blog-section {
  width: 100%;
}
.logout-blog-section {
  padding: 70px 0 0;
}
.in-cover-title {
  color: #fff;
  position: relative;
  padding: 0;
  text-align: center;
  font-size: 3rem;
  line-height: 1.1;
  max-width: 70rem;
}
.blog-pill {
  background-color: #55b3d5;
  color: #101828;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  position: relative;
}
.loading-container {
  margin: 186px auto;
}

.cover-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 364px;
  max-width: 1248px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 7.688rem;

  &.border-radius {
    border-radius: 0 0 24px 24px;
    overflow: hidden;
  }

  img {
    height: 364px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tint {
    position: absolute;
    background-color: rgba(38, 34, 49, 0.55);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .back-button-container {
    position: absolute;
    top: 2rem;
    left: 3.5rem;

    .back-button {
      padding: 0.75rem 1.5rem;
      color: #101828;
      background-color: #d8dce5;
      border: none;
      border-radius: 100px;
      font-size: 1rem;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background-color: #838ea1;
      }

      .back-arrow {
        color: #2d98d2;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .logout-blog-section {
    padding: 70px 0 0;
  }
  .cover-image-container {
    padding: 1.5rem 1rem;
    justify-content: flex-end;

    .back-button-container {
      top: 1rem;
      left: 1rem;

      .back-button {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 3rem;

        .text {
          display: none;
        }

        .back-arrow {
          color: #101828;
        }
      }
    }
  }

  .blog-pill {
    display: none;
  }

  .in-cover-title {
    font-size: 2rem;
    line-height: 1.2;
    text-align: left;
  }
}

.blog-article-author-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;
}

.blog-article-author-card {
  background-color: #f2f5f7;
  width: 100%;
  max-width: 42.5rem;
  border-radius: 24px;
  padding: 1.5rem;
  display: flex;
  gap: 1rem;

  .author-image {
    height: 4rem;
    width: 4rem;
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
    flex-shrink: 0;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
